.timingsParent {
    display: flex;
    position: relative;
    margin: 0 0 12px 0;
    min-height: 52px;
}

.pac-target-input {
    left: 24px !important;
}

.timingsParent .dayParent {
    display: flex;
    position: relative;
    align-items: baseline;
    justify-content: space-between;
    min-width: 150px;
    margin-top: 8px;
    margin-right: 34px;
}

.timingsParent .dayParent .day {
    font-size: 14px;
    font-weight: 500;
}

.timingsParent .dateContainer {
    position: relative;
}

.timingsParent .dateContainer .date2Container {
    display: flex;
    align-items: baseline;
    position: relative;
    margin: 0 0 10px 10px;
}

.timingsParent .dateContainer .date2Container .react-datepicker-wrapper {
    width: auto !important;
}

.timingsParent .dateContainer .date2Container .customDatePicker {
    margin: 0 10px;
    width: 105px !important;
    border-radius: 4px !important;
    direction: ltr !important;
}

.timingsParent .dateContainer .date2Container .addTimings {
    font-size: 14px;
    font-weight: 600;
    color: var(--theme-color-1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timingsParent .dateContainer .date2Container .addTimings i {
    font-size: 14px;
    font-weight: 600;
    color: var(--theme-color-1);
    margin-left: 8px;
    margin-right: 8px;
}

.timingsParent .dateContainer .date2Container .removeTimings i {
    font-size: 14px;
    font-weight: 600;
    color: #d7d7d7;
    cursor: pointer;
}

.customInput {
    border-radius: 4px;
}

.dropzone {
    border: 1px dotted #d7d7d7 !important
}

.btn-primary:not(:disabled):not(.disabled).active {
    background-color: var(--theme-color-1);
    border-color: var(--theme-color-1);
}

.dropdown-toggle.btn.btn-outline-primary {
    padding: 10px;
}