
   .salesTitle{
    font-size: .875rem;
    font-weight: 400;
    color: #adadad;
   }
   .salesValue{
    font-size: 1.3125rem;
    font-weight: 500;
    color: #4a4a4a;
   }

   .react-daterange-picker.react-daterange-picker--enabled{
height: 100%;
width: 100%;
color: hsl(0,0%,50%);
   }
   .react-daterange-picker.react-daterange-picker--open.react-daterange-picker--enabled{
    border:  1px solid #2684FF !important;
    box-shadow: 0 0 0 1px #2684FF;
    border-radius: 4px;
   }
   .react-daterange-picker__wrapper{
     border-radius: 4px;
     border-color: hsl(0,0%,80%);
     cursor: pointer;
   }
   
   .react-daterange-picker__inputGroup{
    display: flex;
    align-items: center;
    justify-content: center;
  }
   
   .react-daterange-picker__inputGroup input{
    color: hsl(0,0%,50%);
    cursor: pointer;
  }
  .react-daterange-picker__inputGroup span{
    color: hsl(0,0%,50%);
    cursor: pointer;
  }
   .react-daterange-picker__inputGroup input:focus {
outline: none !important;
   }
   .react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__year::selection{
background: none;
   }
   .react-daterange-picker__inputGroup__input:invalid{
     background: none;
   }
   .react-calendar__month-view__weekdays__weekday abbr{
text-decoration: none;
   }
   .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day{
    border-top-left-radius: 28px !important;
    border-bottom-left-radius: 28px !important;
   }
   .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day{
    border-top-right-radius: 28px !important;
    border-bottom-right-radius: 28px !important;
   }
   .ReactTable .rt-tr-group{
      cursor: pointer;
   }