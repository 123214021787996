.onboard{
    display: flex;
    min-height: 100vh;
}
.onboard .left_section{
    width: 35%;
    padding: 8px;
}
.onboard .left_section .group{
    padding: 12px 0;
}
.onboard .left_section .row_group{
    padding: 4px 0;
}
.onboard .right_section{
    width: 75%;
}
@media only screen and (max-width: 900px) {
    
    .onboard .left_section{
        width: 100%;
    }
    .onboard .right_section{
        width: 0;
    }
}