.onboardModal{

}
.onboardModal .header_tap{
    padding: 8px 22px;
    background-color: #cacaca;
    border-radius: 4px;
    margin: 0 10px;
}
.onboardModal .step_card{
    padding: 12px;
    background-color: #cacaca;
    margin: 8px 0;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}
.onboardModal .row_group{
    padding: 4px 0;
}