.orderListCustom{
    margin-left: -45px;
    margin-right: -45px;
    margin-top: -45px;
    margin-bottom: -45px;
    height: calc(100vh - 300px);
}
.newPerfectScroll{
    overflow: auto !important;
}

@media (max-width: 768px){
    .orderListCustom{
        margin-left: 0;
        margin-top: 0;
    }
}
@media (min-width:768px) and (max-width: 1199px){
    .orderListCustom{
        margin-left: -35px;
        margin-right: -35px;
        margin-top: -40px;
    }
}
@media (min-width:1199px) and (max-width: 1439px){
    .orderListCustom{
        margin-left: -35px;
        margin-right: -35px;
        margin-top: -40px;
    }
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled{
    color: #dc3545 !important;
}

@media (max-width: 992px){
    .reciptMobileContainer{
        position: absolute;
        height: calc(100vh - 205px) !important
    }
    .reciptMobileContainerCard{
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
   
    .scrollOrderListMobileContainerCard{
        border-radius: 16px !important;
        height: calc(100vh - 205px) !important
    }
    .ordersBackButton{
        position: absolute;
        color: #3489db;
        margin-left: 35px;
        margin-top: 15px;
        z-index: 100;
    }
}

@media (min-width: 992px){
    .ordersBackButton{
        display: none;
        
    }
    .scrollOrderListMobileContainer{
        padding: 0;
    }
}

.setupTitle{
    font-weight: 500;
    font-size: 28px;
    color: #303030;
}
.setupSubTitle{
    font-weight: 400;
    font-size: 0.9rem;
    color: #8f8f8f;
    margin-bottom: 2.5rem !important;

}
.setupItemsContainer{
    font-weight: 400;
    font-size: 16px;
    position: relative;
    display: flex;
    /* align-items: ; */
    justify-content: flex-start;
}

.setupItemsContainer .sectionDot{
    position: relative;
    margin-right:8px;
    margin-left:8px;
}
.setupItemsContainer .setupTitleContainer{
    position: relative;
    width: 100%;
}
.setupItemsContainer .setupTitleContainer .title{
    font-weight: 700;
    font-size: 1.05rem;
    margin-bottom: 4px;    
    color: #4a4a4a;

}
.setupItemsContainer .setupTitleContainer .description{
    font-weight: 400;
    font-size: .875rem;
}
.setupItemsContainer .setupTitleContainer .description a{
    font-weight: 400;
    font-size: .875rem;
    color: var(--theme-color-1) ;
}

.PhoneInputCountryIcon--border{
    background-color: transparent !important;
    box-shadow: none !important;
    height: 100% !important;
}
.PhoneInputInput{
    border: none;
    outline: none;
}
.react-datepicker__time-container {
    width: 100% !important;
}
.btn-lg{
    /* padding: 0.5rem; */
    border-radius: 4px !important; 
    font-weight: 800;         
}