.storeDetails {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.storeDetails .storeDetailsLeftSide {
    width: 40%;
    background-color: #FDF8F0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storeDetails .storeDetailsRightSide {
    width: 60%;
    padding: 79px 130px;
}

.storeDetails .storeDetailsRightSide .stepTitle {
    font-weight: 600;
    color: var(--theme-color-1);
    font-size: 14px;
}

.storeDetails .storeDetailsRightSide .welcomeTitle {
    font-weight: 700;
    color: #0A0A0A;
    font-size: 24px;
}

.storeDetails .storeDetailsRightSide .desc {
    color: #0A0A0A;
    font-size: 14px;
}

.storeDetails .storeDetailsRightSide .detailsTitle {
    color: #B4B4B4;
    font-weight: 500;
    font-size: 16px;
    margin-top: 40px;
}

.storeDetails .storeDetailsRightSide .inputsContainer2 {
    margin-top: 18px;
}

.storeDetails .storeDetailsRightSide .inputsContainer2 .inputsTitle {
    font-weight: 500;
    font-size: 14px;
    color: #0A0A0A;
}

.storeDetails .storeDetailsRightSide .inputsContainer2 .inputsTitle span {
    color: var(--theme-color-1);
}


.storeDetails .storeDetailsRightSide .inputsContainer2 .inputStyle {
    height: 48px;
    border-radius: 8px;
    border-color: #70707050;
    margin-top: 8px;
}

.storeDetails .storeDetailsRightSide .inputsContainer2 .inputStyle .css-yk16xz-control {
    border-radius: 8px;
    height: 48px;
    border-color: #70707050;
    margin-top: 8px;

}

.storeDetails .storeDetailsRightSide .plansContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
.storeDetails .storeDetailsRightSide .plansContainer .incrementCard{
    display: flex;
    justify-content: space-between;
    background-color: rgb(250, 250, 250);
    border-radius: 8px;
    padding-bottom: 4px;
}
.storeDetails .storeDetailsRightSide .plansContainer .incrementCard .btns{
    width: 25px;
    height: 25px;
    color: rgb(254, 185, 46);
    background-color: rgba(254, 185, 46, 0.19);
    margin: 4px;
    text-align: center;
    font-size: 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storeDetails .storeDetailsRightSide .plansContainer .activePlan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--theme-color-1);
    padding: 17px;
    border-radius: 8px;
    cursor: pointer;
    flex: 1;
}

.storeDetails .storeDetailsRightSide .plansContainer .notActivePlan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-radius: 8px;
    background-color: #FAFAFA;
    padding: 17px;
    cursor: pointer;

}
.storeDetails .receipt{
    background: #FAFAFA;
    padding: 60px;
    width: 40%
}
.storeDetails .receipt .header{
    font-weight: 700;
    font-size: 16px;
    color: #B4B4B4;
}
.storeDetails .receipt .details{
    font-weight: 500;
    font-size: 14px;
    color: #0A0A0A;
    display: flex;
    padding-top: 8px;
    justify-content: space-between;
}
.storeDetails .receipt .muted{
    color: #B4B4B4;
}
.head {
    font-size: 18px;
    color: #0A0A0A;
    font-weight: 700;
}

.tail {
    font-size: 12px;
    color: #0A0A0A;
}