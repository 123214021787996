.storeItemContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: .875rem;
    cursor: pointer;
}

.storeItemContainer:hover {
    background-color: #e8eef1;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    color: black;
}

.storeItemActive {
    background-color: var(--theme-color-1);
    color: white;
}

.storeItemActive:hover {
    background-color: var(--theme-color-1);
    color: white;
    cursor: pointer;
}